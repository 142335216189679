<template>
  <div>
    <banners-module />
    <div class="border-bottom">
      <header>
        <nav
          class="navbar navbar-default navbar-fixed-top navbar-front nav-project-sell"
        >
          <div class="container">
            <div class="content-element">
              <a
                :href="'tel:' + phoneCompactPrefix"
                class="telephone d-none d-xl-block"
                @click="eventTracking(
                      'call',
                      'clics_module',
                      'header'
                    )"
              >
                <img
                  :src="getPicto('phone', 'svg', 'classique')"
                  :alt="phoneNumber"
                />
                {{ phoneNumber }}
              </a>
              <a class="logo" href="/" :title="title">
                <img
                  :src="
                    getLogos(
                      subdomain ? `logo-${subdomain}` : 'carizy',
                      subdomain ? 'png' : 'svg'
                    )
                  "
                  height="22px"
                  :class="`subdomain-logo FunnelHeader__logo d-sm-none d-none d-md-block logo-${subdomain}`"
                  :alt="title"
                />
              </a>
              <call-back-module />
            </div>
          </div>
        </nav>
      </header>
    </div>
  </div>
</template>

<script>
import CallBackModule from "../../../components/modules/modals/CallbackModule";
import UtilMixin from "../../../mixins/UtilMixin";
import BannersModule from '../../../components/modules/banners/bannersModule.vue'

export default {
  name: "HeaderFunnelModule",
  components: { BannersModule, CallBackModule },
  mixins: [UtilMixin],
  data: () => ({
    subdomain: null,
    phoneNumber: null,
    title: null,
    phoneCompactPrefix: null,
  }),
  mounted() {
    this.subdomain = this.getPartenaire().subdomain;
    this.phoneNumber = this.getPartenaire().phoneNumber;
    this.phoneCompactPrefix = this.getPartenaire().phoneCompactPrefix;
    this.title = this.getPartenaire().title;
    this.route = this.getPartenaire().route;
  }
};
</script>

<style lang="scss">
@import "../../../assets/sass/modules/headerFunnelModule";
</style>
