<template>
  <div id="sidebar">
    <div class="nav navbar-nav menu_mm">
      <div class="menu-toogle">
        <div
          id="burger"
          :class="{ active: isBurgerActive }"
          @click.prevent="toggle"
        >
          <img
            src="../../../assets/img/light/classique/Menuburger.svg"
            alt="Menu burger mobile"
          />
        </div>
      </div>
    </div>
    <div :class="{ active: isBurgerActive }" class="nav-slide sidebar-carizy" id="sidebar-container">
      <transition name="slide">
        <div class="sidebar-panel">
          <nav id="mobile-nav">
            <div class="nav-items">
              <div class="menu-top">
                <ul>
                  <li
                    class="nav-item nav-item-parent"
                    @click="subNavfirst = !subNavfirst"
                  >
                    <div
                      :class="
                        subNavfirst ? ['menu-label menu-open'] : 'menu-label'
                      "
                    >
                      <a
                          :href="validateUrl('/vendre-ma-voiture')"
                          title="vendre ma voiture"
                          @click="eventTracking('vendre_voiture', 'clics_module', 'header')"
                      >
                        Vendre une voiture
                      </a>
                    </div>
                  </li>
                  <ul
                    class="sub-nav"
                    :class="subNavfirst ? '' : 'hide-sub-nav-md'"
                  >
                    <h2 class="title-menu">MON PROJET DE VENTE</h2>
                    <li
                      v-for="seller in sellers"
                      :key="seller.title"
                      class="sub-nav-item"
                    >
                      <a :href="validateUrl(seller.link)">
                        <span class="menu-label">
                          {{ seller.title }}
                        </span>
                      </a>
                    </li>
                    <h2 class="title-menu">COMMENT ÇA MARCHE ?</h2>
                    <li class="sub-nav-item mb-4">
                      <a :href="validateUrl()">
                        <span class="menu-label">
                          Guide “Vendre sa voiture”
                        </span>
                      </a>
                    </li>
                  </ul>
                </ul>
                <ul>
                  <li
                    class="nav-item nav-item-parent"
                    @click="subNavsecond = !subNavsecond"
                  >
                    <div
                      :class="
                        subNavsecond ? ['menu-label menu-open'] : 'menu-label'
                      "
                    >
                      <a
                          :href="validateUrl('/voiture-occasion')"
                          title="voiture occasion"
                          @click="eventTracking('acheter_voiture', 'clics_module', 'header')"
                      >
                        Acheter une voiture
                      </a>
                    </div>
                  </li>
                  <ul
                    class="sub-nav"
                    :class="subNavsecond ? '' : 'hide-sub-nav-md'"
                  >
                    <a
                      :href="validateUrl('/top-marques')"
                      class="btn btn-green-outline mt-4 width-75 margin-auto btn-resize"
                    >
                      <img
                          :src="getPicto('graph-up', 'svg', 'classique')"
                          :alt="'Top marques'"
                          width="26"
                          height="26"
                          class="icon"
                      />
                      TOP MARQUES
                    </a>
                    <a
                      :href="validateUrl('/top-modeles')"
                      class="btn btn-green-outline mt-4 width-75 margin-auto btn-resize"
                    >
                      <img
                          :src="getPicto('graph-up', 'svg', 'classique')"
                          :alt="'Top modèles'"
                          width="26"
                          height="26"
                          class="icon"
                      />
                      TOP MODÈLES
                    </a>
                    <a
                        :href="validateUrl('/voiture-occasion/occasion-exceptionnelle')"
                        class="btn btn-green-outline mt-4 width-75 margin-auto btn-resize"
                    >
                      <img
                          :src="getPicto('graph-down', 'svg', 'classique')"
                          :alt="'Baisse de prix'"
                          width="26"
                          height="26"
                          class="icon"
                      />
                      BAISSE DE PRIX
                    </a>
                    <h2 class="title-menu">COMMENT ÇA MARCHE ?</h2>
                    <li class="sub-nav-item">
                      <a :href="validateUrl('/acheter/acheter-sa-voiture')">
                        <span class="menu-label">
                          Guide “Vendre sa voiture”
                        </span>
                      </a>
                    </li>
                    <h2 class="title-menu">SELECTION PAR CRIT'AIR</h2>
                    <li
                      v-for="critr in critrs"
                      :key="critr.title"
                      class="sub-nav-item"
                    >
                      <a :href="validateUrl(critr.link)">
                        <span class="menu-label">
                          {{ critr.title }}
                        </span>
                      </a>
                    </li>
                    <h2 class="title-menu">SELECTION PAR CARBURANT</h2>
                    <li
                      v-for="fuel in topFuels"
                      :key="fuel"
                      class="sub-nav-item"
                    >
                      <a
                        :href="
                          validateUrl(
                            fuel
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, ''),
                          )
                        "
                      >
                        <span class="menu-label">
                          {{ fuel }}
                        </span>
                      </a>
                    </li>
                    <h2 class="title-menu">FINANCER SON ACHAT</h2>
                    <li class="sub-nav-item">
                      <a
                        :href="creditUrl()"
                        @click="
                          eventTracking('funnel_credit', 'clics_credit', 'menu')
                        "
                      >
                        <span class="menu-label"> Simulateur crédit auto </span>
                      </a>
                    </li>
                    <li
                      v-for="credit in credits"
                      :key="credit.title"
                      class="sub-nav-item credit"
                    >
                      <a :href="validateUrl(credit.link)">
                        <span class="menu-label">
                          {{ credit.title }}
                        </span>
                      </a>
                    </li>
                  </ul>
                </ul>
                <ul>
                  <li
                    class="nav-item nav-item-parent"
                    @click="subNavthird = !subNavthird"
                  >
                    <div
                      :class="
                        subNavthird ? ['menu-label menu-open'] : 'menu-label'
                      "
                    >
                      Nos services
                    </div>
                  </li>
                  <ul
                    class="sub-nav"
                    :class="subNavthird ? '' : 'hide-sub-nav-md'"
                  >
                    <h2 class="title-menu">NOS SERVICES</h2>
                    <li
                      v-for="service in services"
                      :key="service.title"
                      class="sub-nav-item"
                    >
                      <a :href="validateUrl(service.link)">
                        <span class="menu-label">
                          {{ service.title }}
                        </span>
                      </a>
                    </li>
                    <h2 class="title-menu">PARRAINAGE</h2>
                    <li class="sub-nav-item">
                      <a
                        :href="validateUrl('/nos-services/parrainage-vendeur')"
                      >
                        <span class="menu-label"> Parrainer un ami </span>
                      </a>
                    </li>
                    <h2 class="title-menu">INSPECTEUR</h2>
                    <li class="sub-nav-item mb-4">
                      <a
                        :href="validateUrl('/nos-services/devenir-inspecteur')"
                      >
                        <span class="menu-label"> Devenir inspecteur </span>
                      </a>
                    </li>
                  </ul>
                </ul>
                <ul>
                  <li
                    class="nav-item nav-item-parent"
                    @click="subNavforth = !subNavforth"
                  >
                    <div
                      :class="
                        subNavforth ? ['menu-label menu-open'] : 'menu-label'
                      "
                    >
                      Nos conseils
                    </div>
                  </li>
                  <ul
                    class="sub-nav"
                    :class="subNavforth ? '' : 'hide-sub-nav-md'"
                  >
                    <h2 class="title-menu">LE BLOG CARIZY</h2>
                    <li class="sub-nav-item">
                      <a :href="validateUrl('/blog')">
                        <span class="menu-label"> Le blog </span>
                      </a>
                    </li>
                    <h2 class="title-menu">ARTICLES A LA UNE</h2>
                    <ul>
                      <li
                        v-for="article in topArticles"
                        :key="article.title"
                        class="top-articles sub-nav-item"
                      >
                        <a :href="article.link" class="article-container">
                          <span class="menu-label">{{ article.title }}</span>
                        </a>
                      </li>
                    </ul>
                  </ul>
                </ul>
              </div>
              <div class="menu-bott">
                <ul class="mt-4">
                  <li class="nav-item">
                    <a
                      id="click-to-call"
                      :href="'tel:' + phoneCompactPrefix"
                      class="btn-green-outline btn"
                      @click="eventTracking('call', 'clics_module', 'header')"
                    >
                      <img
                          :src="getPicto('phone-green', 'svg', 'classique')"
                          :alt="phoneNumber"
                          class="vertical-align-sub icon"
                      />
                      {{ phoneNumber }}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      :href="validateUrl('/client/login')"
                      class="btn-green-background btn account"
                    >
                      <img
                          :src="getPicto('account-black', 'svg', 'classique')"
                          :alt="'Espace personnel'"
                          width="26"
                          height="26"
                          class="vertical-align-bottom"
                      />
                      Me connecter
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image/v2";
import UtilMixin from "../../../mixins/UtilMixin";
import { store, mutations } from "../../../store/header";

export default {
  name: "BUrgerMenuModule",
  components: { VLazyImage },
  mixins: [UtilMixin],
  props: {
    topArticles: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      subNavfirst: false,
      subNavsecond: false,
      subNavthird: false,
      subNavforth: false,
      phoneNumber: null,
      phoneCompactPrefix: null,
      title: null,
      route: "",
      partner: null,
      sellers: {
        estimation: {
          title: "Estimer sa voiture",
          link: "/vendre/cote-argus",
        },
        sellCar: {
          title: "Vendre ma voiture",
          link: "/vendre-ma-voiture",
        },
      },
      critrs: {
        electric: {
          title: "Électrique & Hydrogène",
          link: "/voiture-occasion/électrique",
        },
        critr1: {
          title: "Crit'Air 1",
          link: "/voiture-occasion/critair-1",
        },
        critr2: {
          title: "Crit'Air 2",
          link: "/voiture-occasion/critair-2",
        },
        critr3: {
          title: "Crit'Air 3",
          link: "/voiture-occasion/critair-3",
        },
      },
      credits: {
        sellOld: {
          title: "Vendre son ancienne voiture",
          link: "/vendre-ma-voiture",
        },
      },
      services: {
        credit: {
          title: "Crédit auto",
          link: "/nos-services/credit-auto",
        },
        insurance: {
          title: "Assurance",
          link: "/nos-services/assurance-auto",
        },
        warranty: {
          title: "Garantie",
          link: "/nos-services/la-garantie-mecanique",
        },
        delivery: {
          title: "Livraison",
          link: "/nos-services/la-livraison-avec-carizy",
        },
        grayCard: {
          title: "Carte Grise & Immatriculation",
          link: "/nos-services/carte-grise-et-assurance",
        },
        moovizy: {
          title: "Moovizy",
          link: "/nos-services/moovizy",
        },
      },
      topFuels: ["Essence", "Diesel", "Hybride", "Électrique"],
    };
  },
  computed: {
    isBurgerActive() {
      return store.isNavOpen;
    },
  },
  mounted() {
    // Partnaire loading
    this.partner = this.getPartenaire().subdomain;
    this.phoneNumber = this.getPartenaire().phoneNumber;
    this.phoneCompactPrefix = this.getPartenaire().phoneCompactPrefix;
    this.title = this.getPartenaire().title;
    this.route = this.getPartenaire().route;

    switch (this.partner) {
      case 'maif':
        this.services.credit.link = "https://www.maif.fr/vehicule-mobilite/simulation-credit-auto?xtor=AL-5346-[fp-vehicule]";
        break
      case 'macif':
        this.services.credit.link =
            "https://www.macif.fr/assurance/particuliers/banque-credit-epargne/credit-automoto?cid=par_Carizy_Credit_TarificationAuto";
        break
      case 'matmut':
        this.services.credit.link =
            "https://www.matmut.fr/epargne-credit/credit-auto";
        break
    }
  },
  methods: {
    toggle() {
      mutations.toggleNav();
    },
    creditUrl() {
      switch (this.partner) {
        case 'maif':
          return "https://www.maif.fr/vehicule-mobilite/simulation-credit-auto?xtor=AL-5346-[fp-vehicule]";
          break
        case 'macif':
          return "https://www.macif.fr/assurance/particuliers/banque-credit-epargne/credit-automoto?cid=par_Carizy_Credit_TarificationAuto";
          break
        case 'matmut':
          return "https://www.matmut.fr/epargne-credit/credit-auto";
          break
        default:
          return "/credit" + this.route;
      }
    },
    validateUrl(url) {
      return url + this.route;
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/sass/modules/_burgerModule";
</style>
